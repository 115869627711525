import React from 'react';
import Popup from 'reactjs-popup';
import CloseIcon from '@material-ui/icons/Close';
// import { useCookies } from "react-cookie";

import { UserService } from '../../service/user.service';
import { GetTimeStemp } from '../..//utility/help';
// import { UserService } from '../service/user.service';

interface Props {
  SetCookie: (name: string, data: any) => any;
  GetCookie: (name: string) => any;
}

interface State {
  note_popup: any[];
  close_popup: any;
}

export class NotePopup extends React.Component<Props, State> {
  userService: UserService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = { note_popup: [], close_popup: {} };

   
    this.userService.get_notice_popup().then((data: any) => {
      this.setState({ note_popup: data.note });
    });
     // const [setCookie] = useCookies(["popup"]);
  }

  componentDidMount() {
 

    //this.MoveMatchPage(0)
  }

  render() {
    let note_popup = this.state.note_popup ?? [];
    let close_popup = this.state.close_popup ?? {};

    const RenderPopup = (pop: any) => {
      let cookie = this.props.GetCookie(`pop_${pop.idx}`);

    if (
      cookie != null &&
      GetTimeStemp() - Number(cookie) < 60 * 60 * 1000 * 8
    ) {
      console.log( 'GetTimeStemp() - Number(cookie)' )

      return (<></>);
    }

    if(this.state.close_popup[`pop_${pop.idx}`] == true){
      return (<></>);
    }

      return (
        <div className="pop01_popup1 draggable02" id="divpopup20210131045005" style={{position: 'absolute', top: `${pop.posX}px`, left: `${pop.posY}px`, zIndex: 1000}} >
          <div className="pop01_popup_wrap">
              <div className="pop01_popup_btn_wrap">
                  <ul>
                      <li><a href="#"><span className="pop01_popup_btn" onClick={()=>{
                        this.props.SetCookie(`pop_${pop.idx}`, GetTimeStemp())
                        this.state.close_popup[`pop_${pop.idx}`] = true
                        this.setState({ close_popup : this.state.close_popup })                        
                        }}>8시간동안 창을 열지 않음</span></a></li>
                      <li><a href="#"><span className="pop01_popup_btn" onClick={()=>{
                        this.state.close_popup[`pop_${pop.idx}`] = true
                        this.setState({ close_popup : this.state.close_popup })}}>닫기 X</span></a></li>            
                  </ul>
              </div>
              <div className="pop01_popup_box">
                <div className="pop01_popup_text" style={{padding:'30px', width:'500px'}}>
                    <span className="pop01_popup_font1" style={{borderBottom:'2px solid #fff', marginBottom:'15px', color : pop.titleColor}}>{pop.title}</span>
                    <span className="pop01_popup_font2" >
                          <div dangerouslySetInnerHTML={{ __html:  pop.contents }}></div>
                    </span> 

                  </div>

            

              </div>
          </div>
      </div>)
      //   <Popup
      //     className=""
      //     key={`main_popup_note_${pop.idx}`}
      //     open={close_popup[`main_popup_note_${pop.idx}`] ?? true}
      //     contentStyle={{
      //       // width: '50%',
      //       background: 'none',
      //       borderRadius: '5px',
      //       width: `${pop.width}px`,
      //       height: `${pop.height}px`,

      //       top: `${pop.posX}px`,
      //       left: `${pop.posY}px`,

      //       position: 'fixed',
      //       border : 'none'

      //     }}
      //     overlayStyle={{
      //       background: 'none',
      //       border : 'none'
      //     }}
          
      //     arrowStyle={{
      //       background: 'none',
      //       border : 'none'
      //     }}
      //     onClose={() => {}}
      //   >
      //     {(close) => (
      //       <div className="modal" style={{}}>
      //         <div

      //           style={{border : 'none'}}
      //           onClick={() => {
      //             this.props.SetCookie(`pop_${pop.idx}`, {
      //               // timeStemp: GetTimeStemp(null),
      //               isPop: true,
      //             });

      //             close_popup[`main_popup_note_${pop.idx}`] = false;

      //             this.setState({ close_popup: close_popup });
      //           }}
      //         >
      //           {/* <i style={{ color: '#fff' }}>
      //             8시간 동안 열지 않음 <CloseIcon />
      //           </i> */}
      //         </div>
      //         <div dangerouslySetInnerHTML={{ __html: pop.contents }} />
      //       </div>
      //     )}
      //   </Popup>
      // );
    };

    return <div>{note_popup.map((pop, index) => RenderPopup(pop))}</div>;
  }
}
