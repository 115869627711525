import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CopyrightIcon from "@material-ui/icons/Copyright";
// const drawerWidth = 440;
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";
import { normalizeUnits } from "moment";
import { UserService } from "../../service/user.service";
import { SlotService } from "../../service/slot.service";

import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notie } from "./notie";
import { Reg } from "./reg";
import { Login } from "./login";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  login = "login",

  reg = "reg",
}
const CustomButton = styled(Button)`
  /* background-color: #6772e5; */
  /* color: #000;
  line-height: normal;
  font-size: 12px;
  font: bold; */

  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  background-color: #d0451b;
  border-radius: 3px;
  border: 1px solid #942911;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 16px;
  /* padding: 6px 24px; */
  text-decoration: none;
  width: 20%;
  text-shadow: 0px 1px 0px #854629;
`;

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomToolbar = styled(Toolbar)`
  /* .muitoolbar-regular : {
  } */
`;

interface Props {
  classes: any;
  user: any;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;

  //   session: any;
}

async function sleep(ms: any) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}



interface State {
  ID: string;
  PW: string;

  balance: number;

  postCount: number;

  note: any;
  isOpen: boolean;

  popupStatuses: string;
}
class topBar extends Component<Props, State> {
  static propTypes: { classes: PropTypes.Validator<object> };
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      isOpen: false,
      popupStatuses: popupView.none,
    };

  
    setImmediate(async() => {
      try{
        for (; ;) {
          this.handleUpdateNote();
  
          await sleep(30000)
        }
      }
      catch(ex)
      {

      }
    });
  }

  componentDidMount() {

    this.handleUpdateInBalance();
  }

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
      } else {
      }
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          postCount: data.count,
          note: data.note,
          isOpen: data.count > 0 ? true : false,
        });
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  render() {
    const classes = this.props.classes;
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        return <Deposit handleClose={this.handleClosePopup}></Deposit>;
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
          ></Withdraw>
        );
      }
      if (this.state.popupStatuses === popupView.notice) {
        return <Notie handleClose={this.handleClosePopup}></Notie>;
      }

      if (this.state.popupStatuses === popupView.reg) {
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }

      if (this.state.popupStatuses === popupView.login) {
        return <Login handleClose={this.handleClosePopup} tryLogin={this.props.tryLogin}></Login>;
      }

      if (this.state.popupStatuses === popupView.help) {
        return <Help handleClose={this.handleClosePopup}></Help>;
      }
      if (this.state.popupStatuses === popupView.user) {
        return <User handleClose={this.handleClosePopup}></User>;
      }

      return <div></div>;
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <div className="mo_top_wrap">
            <div className="mo_top_box">
              <div className="mo_logo">
                <a
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <img src="/web/images/logo/all.png" />
                </a>
              </div>
               <div className="mo_login_wrap">
                <div className="mo_login">
                  <div>
           
                     <div>
                      <a
                        title="로그인"
                        onClick={() => {
                          this.setState({
                            popupStatuses: popupView.login,
                          });
                        }}
                      >
                        <img src="/web/images/btn_login.png"  style={{height: "32px"}}/>
                      </a>
                    </div>
                    <div>
                      <a
                        className="mo_fade_0_1_open"
                        title="회원가입"
                        onClick={() => {
                          this.setState({
                            popupStatuses: popupView.reg,
                          });
                        }}
                      >
                        <img src="/web/images/btn_logout.png"  style={{height: "32px"}}/>
                      </a>
                    </div> 
                  </div>
                </div>
              </div> 
            </div>
          </div>
        );
      }

      return (
        <div className="mo_top_wrap">
          <div className="mo_top_box">
            <div className="mo_logo">
              <a
                onClick={() => {
                  window.location.reload();
                }}
              >
                <img src="/web/images/logo/all.png" />
              </a>
            </div>
            <div className="mo_login_wrap">
              <div className="mo_login">
                <div>

                  <div>
                    <i className="mo_gui gui-slot3"></i> 슬롯머니{" "}
                    <span className="mo_font05">
                      {ConverMoeny(this.state.balance)}
                    </span>
                    <RefreshIcon
                      onClick={() => {
                        this.handleUpdateInBalance();
                      }}
                    ></RefreshIcon>
                    원 /
                  </div>
                  <div>
                    <i className="mo_gui gui-envelope-o"></i> 쪽지{" "}
                    <span className="mo_font05">({this.state.postCount})</span>통{" "}
                  </div>
              
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="mo_header_wrap">
        {RenderLogin()}
        <div className="mo_gnb">
          <div>
            <div>
              <div
                className="mo_fade_1_1_open slide_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.deposit,
                  });
                }}
              >
                입금신청
              </div>
            </div>
            <div>
              <img src={"/web/images/gnb_line.png"} />
            </div>
            <div>
              <div
                className="mo_fade_2_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.withdraw,
                  });
                }}
              >
                출금신청
              </div>
            </div>
            <div>
              <img src="/web/images/gnb_line.png" />
            </div>
            <div>
              <div
                className="mo_fade_3_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.help,
                  });
                }}
              >
                고객센터
              </div>
            </div>
            <div>
              <img src="/web/images/gnb_line.png" />
            </div>
            <div>
              <div
                className="mo_fade_5_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.user,
                  });
                }}
              >
                마이페이지
              </div>
            </div>
            <div>
              <img src="/web/images/gnb_line.png" />
            </div>
            <div>
              <div
                className="mo_fade_6_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.notice,
                  });
                }}
              >
                공지사항
              </div>
            </div>

              {
                authenticated === true && (
                  <>
                    <div>
                      <img src="/web/images/gnb_line.png" />
                    </div>
                    <div>
                      <div
                        className="mo_fade_6_1_open"
                        onClick={() => {
                          this.props.tryLoginOut()
                        }}
                      >
                        로그아웃
                      </div>
                    </div>
                  </>
             
                ) 

              }
          </div>
        </div>
  
        {RenderPopup()}
      </div>
    );
  }
}

topBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let TopBar = withStyles(styles, { withTheme: true })(topBar);
export default TopBar;
