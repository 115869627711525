import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CopyrightIcon from "@material-ui/icons/Copyright";
// const drawerWidth = 440;
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";


import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoodIcon from '@material-ui/icons/Mood';
import DeleteIcon from '@material-ui/icons/Delete';


import { confirmAlert } from "react-confirm-alert"; // Import
import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";
import { normalizeUnits } from "moment";
import { UserService } from "../../service/user.service";
import { SlotService } from "../../service/slot.service";

import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notie } from "./notie";
import { Reg } from "./reg";
import { Point } from "./point";
import Popup from 'reactjs-popup';

import { BlueButton, GreenButton, RedButton, } from '../../utility/custom.button';

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
}
const CustomButton = styled(Button)`
  /* background-color: #6772e5; */
  /* color: #000;
  line-height: normal;
  font-size: 12px;
  font: bold; */

  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  background-color: #d0451b;
  border-radius: 3px;
  border: 1px solid #942911;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 16px;
  /* padding: 6px 24px; */
  text-decoration: none;
  width: 20%;
  text-shadow: 0px 1px 0px #854629;
`;

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomToolbar = styled(Toolbar)`
  /* .muitoolbar-regular : {
  } */
`;



async function sleep(ms: any) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}


interface Props {
  classes: any;
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;

  //   session: any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  isOpen: boolean;
  popupStatuses: string;
  expanded: string;
  notes: any;

}
class topBar extends Component<Props, State> {
  static propTypes: { classes: PropTypes.Validator<object> };
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      isOpen: false,
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: '',


    };

    this.handleUpdateNote();

      setImmediate(async() => {
        try{
          for (; ;) {
            this.handleUpdateNote();
    
            await sleep(10000)
          }
        }
        catch(ex)
        {

        }
      });
      // this.updateUser();
  }

  componentDidMount() {
 
    this.handleUpdateInBalance();
  }

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
      } else {
      }
    });
  };

  

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {

        if(!this.state.isOpen){
          this.setState({
            postCount: data.count,
            note: data.note,
            isOpen: data.count > 0 ? true : false,
          });
  
        }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  
      
  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === 'success') {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = 'y';
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {

        window.location.reload();
      });
    }
  };



  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {

        window.location.reload();
      });
    }
  };

  

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === 'success') {
          this.setState({
            notes: data.note,
          });
        }
      });
    }
  };


  render() {
    const classes = this.props.classes;
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    console.log(this.props.activeHelp)



    const RenderPopup = () => {

      if (this.state.popupStatuses === popupView.deposit) {
          
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }

        return <Deposit handleClose={this.handleClosePopup}></Deposit>;
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
          ></Withdraw>
        );
      }
      if (this.state.popupStatuses === popupView.notice) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })
                },
              },
            ],
          });
          return;
        }
        return <Notie handleClose={this.handleClosePopup}></Notie>;
      }

      if (this.state.popupStatuses === popupView.note) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }

      if (this.state.popupStatuses === popupView.point) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return <Point 
        handleClose={this.handleClosePopup}
        user={this.props.user}

        ></Point>;
      }
      
      
      if (this.state.popupStatuses === popupView.reg) {
   
        return <Reg handleClose={this.handleClosePopup}></Reg>;
      }

      if (this.state.popupStatuses === popupView.help) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return <Help handleClose={this.handleClosePopup}></Help>;
      }
      if (this.state.popupStatuses === popupView.user) {
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })

                },
              },
            ],
          });
          return;
        }
        return <User handleClose={this.handleClosePopup}></User>;
      }

      return <div></div>;
    };

    const RendePopup = () => {
      if (0 < this.state.noteCount || this.state.isOpen === true) {
        const handleChange = (panel: string) => (
          event: React.ChangeEvent<{}>,
          isExpanded: boolean,
        ) => {
          let expanded =
            this.state.expanded === panel && panel !== '' ? '' : panel;
          this.setState({ expanded: expanded });

          this.handleReadNote(panel);
        };

        return (
          <Popup
            open={this.state.isOpen}
            onClose={() => {
              this.setState({ isOpen: false });
            }}
            contentStyle={{
              width: '80%',
              padding: '0px',
              background: 'none',
              border: 'none',
            }}
            overlayStyle={{
              border: 'none',
            }}
          >
            {(close) => (
              <div>
                <div
                  className="dark_title box_size sub_title"
                  style={{ background: 'black' }}
                >
                  {/* <span style={{ paddingRight: 20 }}>
                    <RedButton
                      color="secondary"
                      variant="contained"
                      style={{ padding: "0px" }}
                      onClick={() => this.do_del_all_note()}
                    >
                      {'전체읽음'}
                    </RedButton>
                  </span>
                  <span>
                    <GreenButton
                      variant="contained"
                      style={{ padding: "0px", color: "#ffff" }}
                      onClick={() => this.do_read_all_note()}
                    >
                      {'전체삭제'}
                    </GreenButton>
                  </span> */}
                </div>
                <div>
                  {this.state.note.map((info: any) => (
                    <ExpansionPanel
                      key={info._id}
                      expanded={this.state.expanded === info._id}
                      onChange={handleChange(info._id)}
                      style={{ margin: '0px' }}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        style={{ margin: '0px' }}
                      >
                        <Typography
                          style={{
                            width: '70%',
                            paddingTop: '10px',
                            textAlign: 'left',
                            color: '#ffeb3b'
                          }}
                        >
                          {info.title}
                        </Typography>
                        <Typography
                          style={{
                            width: '29%',
                            color: '#000',
                            paddingTop: '10px',
                          }}
                        >
                          {ConvertDate(info.regDate)}
                        </Typography>
                        <Typography style={{ width: '150px', color: '#000' }}>
                          {info.isRead === 'y' ? (
                            <GreenButton
                              color="secondary"
                              variant="contained"
                              startIcon={<MoodIcon />}
                            >
                              {'확인'}
                            </GreenButton>
                          ) : (
                              <BlueButton
                                color="secondary"
                                variant="contained"
                                startIcon={<MoodIcon />}
                              >
                                {'미확인'}
                              </BlueButton>
                            )}
                        </Typography>
                        <Typography style={{ width: '150px', color: '#000' }}>
                          {/* BlueButton, GreenButton, RedButton */}
                          <RedButton
                            variant="contained"
                            color="secondary"
                            disableElevation={false}
                            startIcon={<DeleteIcon />}
                            onClick={() => {
                              this.handleDel(info._id);
                            }}
                          >
                            삭제
                          </RedButton>
                        </Typography>
                      </ExpansionPanelSummary>{' '}
                      {/* <RedButton
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          this.handleDel(info._id);
                        }}
                      >
                        삭제하기
                      </RedButton>{' '} */}
                      <ExpansionPanelDetails>
                        <Typography>
                          <div
                            dangerouslySetInnerHTML={{ __html: info.text }}
                          ></div>
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ))}
                </div>
              </div>
            )
            }
          </Popup >
        );
      }
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <div className="top_wrap">
            <div className="top_box">
              <div className="logo">
                <a
                  onClick={() => {
                    window.location.reload();
                  }}
                >
              
                <a href="./" className="bs-logo" style={{display: 'block'}}>
                  <img src="/web/images/logo/all.png"  />

                </a>



                </a>
              </div>
              <div className="login_wrap">
                <div className="login">
                  
                <form >

                    <div>
                      <div>
                        <img src="/web/images/login_title.png" />
                      </div>
                      <div>
                        <input
                          name="id"
                          type="text"
                          className="input_login"
                          onChange={({ target: { value } }) =>
                            this.setState({ ID: value })
                          }
                        />
                      </div>
                      <div>
                        <input
                          name="password"
                          type="password"
                          className="input_login"
                          onChange={({ target: { value } }) =>
                            this.setState({ PW: value })
                          } 
                        />
                      </div>
                      <div>
                        <a
                          title="로그인"
                          onClick={() => {
                            if(this.state.ID == null || this.state.ID == ""|| this.state.PW == "" || this.state.PW == null){
                              return 
                            }
                            this.props.tryLogin(this.state.ID, this.state.PW);
                          }}
                        >
                          <img src="/web/images/btn_login.png"  style={{height: "32px"}}/>
                        </a>
                      </div>
                      <div>
                        <a
                          className="fade_0_1_open"
                          title="회원가입"
                          onClick={() => {
                            this.setState({
                              popupStatuses: popupView.reg,
                            });
                          }}
                        >
                          <img src="/web/images/btn_logout.png"  style={{height: "32px"}}/>
                        </a>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="top_wrap">
          <div className="top_box">
            <div className="logo">
              <a
                onClick={() => {
                  window.location.reload();
                }}
              >

                <a href="./" className="bs-logo" style={{display: 'block'}}>
                  <img src="/web/images/logo/all.png"  />
                </a>
              </a>
            </div>
            <div className="login_wrap">
              <div className="login">
                <div>
                  <div>
                    <span className="font05">{user.id}</span> 님 환영합니다. /{" "}
                  </div>
                  <div>
                    <i className="gui gui-slot3"></i> 슬롯머니{" "}
                    <span className="font05">
                      {ConverMoeny(this.state.balance)}
                    </span>
                    <RefreshIcon
                      onClick={() => {
                        this.handleUpdateInBalance();
                      }}
                    ></RefreshIcon>
                    원 /
                  </div>
                  <div onClick={()=>{this.setState({isOpen : true})}}>
                    <i className="gui gui-envelope-o"></i> 쪽지{" "}
                    <span className="font05">({this.state.postCount})</span>통 /{" "}
                  </div>
                  <div>
                    <a
                      onClick={() => {
                        this.props.tryLoginOut();
                      }}
                    >
                      <img src="/web/images/btn_loginout1.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="header_wrap">
        {RenderLogin()}
        <div className="gnb">
          <div>
            <div>
              <div
                className="fade_1_1_open slide_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.deposit,
                  });
                }}
              >
                입금신청
              </div>
            </div>
            <div>
              <img src={"/web/images/gnb_line.png"} />
            </div>
            <div>
              <div
                className="fade_2_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.withdraw,
                  });
                }}
              >
                출금신청
              </div>
            </div>
            <div>
              <img src="/web/images/gnb_line.png" />
            </div>
            <div>
              <div
                className="fade_2_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.point,
                  });
                }}
              >
                금고
              </div>
            </div>
            <div>
              <img src="/web/images/gnb_line.png" />
            </div>
            <div>
              <div
                className={this.props.activeHelp ? 'fade_3_1_open textAnimation' : 'fade_3_1_open' }
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.help,
                  });
                }}
              >
                고객센터
              </div>
            </div>
            <div>
              <img src="/web/images/gnb_line.png" />
            </div>
            <div>
              <div
                className="fade_5_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.user,
                  });
                }}
              >
                마이페이지
              </div>
            </div>
            <div>
              <img src="/web/images/gnb_line.png" />
            </div>
            <div>
              <div
                className="fade_6_1_open"
                onClick={() => {
                  this.setState({
                    popupStatuses: popupView.notice,
                  });
                }}
              >
                공지사항
              </div>
            </div>
          </div>
        </div>
        <div className="notice_wrap">
          {/* <div className="notice_box">
            <img src="/web/images/notice_title.png" /> 안녕하십니까? 카지노에
            오신것을 진심으로 환영합니다.{" "}
            <span className="font05">
              입금 계좌는 수시로 변경되오니 입금전 꼭 계좌번호를 고객센터로 문의
            </span>
            해 주시기 바랍니다.
          </div> */}
        </div>

        {RendePopup()}
        {RenderPopup()}
      </div>
    );
  }
}

topBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let TopBar = withStyles(styles, { withTheme: true })(topBar);
export default TopBar;
