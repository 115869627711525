import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { confirmAlert } from "react-confirm-alert"; // Import

import { SlotService } from "../../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

interface Props {}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos : any
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos : []
    };


  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

     
    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ casionos: s.casiono });
      }
    });
  }

  handleOpenSlot = (code: string, company : string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      } else if (data.status === "fix_server") {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoFix,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      } else {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoPermission,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      }
    });
  };

  handleGame = (name: string) => {
    this.slotService.get_slot_by_company(name).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  };

  RenderSlot = (info: any) => {

    return (
      <li
        className={info.nameEn}
        onClick={() => {
          if (info.used === "y") {
            this.setState({ mode: Mode.none });
            this.handleGame(info.code);
          } else {
            confirmAlert({
              title: "점검중",
              message: "현재 해당게임은 점검중입니다 .",
              buttons: [
                {
                  label: "확인",
                  onClick: () => {},
                },
              ],
            });
          }
        }}
      >
        <div style={{backgroundImage: `url(${info.mobileImg})`}}>
          {/* <span className="studio">{info.nameKo}</span> */}
          {/* <span className="ico">
            <img src={`/web/images/icon-${info.nameEn}.png`} />
          </span> */}
          {/* <span className="text">{info.nameKo}</span>
          <span className="play">플레이하기</span> */}
        </div>
      </li>
    );
  };

  RenderGame = (info: any) => {
    return (
      <li
        className={info.nameEn}
        onClick={() => {
          this.handleOpenSlot(info.code, info.gameCompany)
        }}

        style={{
          top: "65%",
          height: '180px'
        }}
      >
        <span>
      

          <img
            src={`${info.imgUrl}`}
            style={{
               maxWidth: "100%",
            }}
          />
              <strong>

                      
            <p
              className="studio"
              style={{
                top: "85%",
                textAlign: "center",
                fontSize: '12PX',
                margin : '0px'
                //  left: "50%",

                //  transform: `translateX(-40%) translateY(50%)`,
              }}
            >
              {info.nameKo}
            </p>
            </strong>
          {/* <span className="text">{info.nameKo}</span>
          <span className="play">{info.nameKo}</span> */}
        </span>
      </li>

      // <li
      //   className={info.nameEn}
      //   onClick={() => {
      //     this.handleOpenSlot(info.code, info.gameCompany)
      //   }}

      //   style={{
      //     top: "65%",
      //     height: '180px'
      //   }}
      // >
      //   <span>
      

      //     <img
      //       src={`${info.imgUrl}`}
      //       style={{
      //          maxWidth: "100%",
      //       }}
      //     />
      //         <strong>

                      
      //       <p
      //         className="studio"
      //         style={{
      //           top: "85%",
      //           textAlign: "center",
      //           fontSize: '12PX',
      //           margin : '0px'
      //           //  left: "50%",

      //           //  transform: `translateX(-40%) translateY(50%)`,
      //         }}
      //       >
      //         {info.nameKo}
      //       </p>
      //       </strong>
      //     {/* <span className="text">{info.nameKo}</span>
      //     <span className="play">{info.nameKo}</span> */}
      //   </span>
      // </li>
    );
  };

  
  
  RenderCasino = (info: any) => {

    return (
      <li key={`key${info.nameEn}kkk`}
        className={info.nameEn}
        onClick={() => {
          if (info.used === "y") {
            this.handleOpenSlot(info.code, info.gameCompany)
          } else {
            confirmAlert({
              title: "점검중",
              message: "현재 해당게임은 점검중입니다 .",
              buttons: [
                {
                  label: "확인",
                  onClick: () => {},
                },
              ],
            });
          }
        }}
        style={{ width : '48%', padding : '5px'}}
      >
        <div style={{backgroundImage: `url(${info.mobileImg})`, width: '100%'}} >
     
          {/* <span className="text">{info.nameKo}</span> */}
          {/* <span className="play">플레이하기</span> */}
        </div>
      </li>
    );
  };
  render() {
    console.log(this.state.mode)
    if (this.state.mode === Mode.none) {
      return (
        <div className="mo_game_wrap">
          <div className="mo_game_box">
            <div
              style={{
                textAlign: "center",
                zoom: 10,
              }}
            >
              <CircularProgress />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.mode === Mode.game) {
      return (
        <div className="mo_game_wrap">
          <div className="mo_game_box">
            <div className="mo_slots">
              {this.state.games.map((row: any) => this.RenderGame(row))}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="mo_game_wrap">

        <div className="mo_game_box">
          <div className="mo_slots">
            
        <div style={{ padding: '20px'}}>
          <img src="/web/images/slot.png" style={{width : '100%'}}  />
        </div>
            {this.state.slots.map((row: any) => this.RenderSlot(row))}
          </div>
        </div>
        {
            process.env.REACT_APP_API_CASINO === 'Y' && (
   
              <div className="mo_game_box">
                <div className="mo_slots">
                    <div style={{ padding: '20px'}}>
                    <img src="/web/images/livecasino.png" style={{width : '100%'}}  />
                  </div>

                  {this.state.casionos.map((row: any) => this.RenderCasino(row))}
                </div>
              </div>
            )
        }
      </div>
    );
  }
}
