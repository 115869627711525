import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../../service/balance.service";

import {
  ConverMoeny,
} from "../../utility/help";

interface Props {
  user : any
  handleClose: () => any;
}

interface State {
  balance: number;
  point : number
}

export class Point extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      point : 0,
    };
  }


  
  handleChangeToBalance = () => {
    if(this.state.balance <= 0 ){
        confirmAlert({
            title: "벨런스",
            message: "벨런스를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "벨런스",
        message: "보유머니를 입금 하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {

                this.balanceService.user_balance_to_point(this.state.balance).then((data: any) => {
                    if (data.status === "success") {
                      confirmAlert({
                        title: "벨런스",
                        message: "벨런스를 금고머니로 변경하였습니다.",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              window.location.reload()
                            },
                          },
                        ],
                      });
            
                    }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };

  handleChangeToPoint = () => {
    if( this.state.point <= 0 ){
        confirmAlert({
            title: "금고머니",
            message: "금고머니를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
          return ;
    }

    confirmAlert({
        title: "금고머니",
        message: "금고머니를 출금하시겠습니까?",
        buttons: [
          {
            label: "확인",
            onClick: () => {
                this.balanceService.user_point_to_money(this.state.point).then((data: any) => {
                    if (data.status === "success") {
                        confirmAlert({
                            title: "금고머니",
                            message: "금고머니를 벨런스로 변경하였습니다.",
                            buttons: [
                              {
                                label: "확인",
                                onClick: () => {
                                  window.location.reload()
                                },
                              },
                            ],
                          });
                  }
                });
            },
          },

          {
            label: "취소",
            onClick: () => {

            },
          },

        ],
      });
     

  };



  render() {

    console.log(this.props.user)
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99999,
          background: "none",
          border: "none",
          width: "none",
        }}
        onClose={() => {}}
      >
        {(close) => (
          <div className="fade_1_1">
            <div className="close_wrap">
              <div className="close_box">
                <a
                  href="#"
                  className="fade_1_1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/web/images/close.png" />
                </a>
              </div>
            </div>
            <div className="popup_wrap">
              <div className="popup_box">
                <div className="popup_start">
                  <div className="popup">
                    <div className="title_wrap">
                      <div className="title">
                      금고 <span className="title2">BANK</span> 
                      {
                        this.props.user && 
                        <span >금고 보유금 : {ConverMoeny(this.props.user.point)}</span>
                      }
                        <span>
                          <img src="/web/images/logo.png" width="120" />
                        </span>
                      </div>
                    </div>
                    {/* <div className="con_box10">
                      <div className="tab_wrap">
                        <ul>
                          <li>
                            <a href="#">
                              <span className="tabon">입금신청</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="tab">출금신청</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    {/* <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info2">
                          수표입금시 입금처리 절대 되지 않습니다.
                        </div>
                        <div className="info3">
                          <span className="font06">
                            최소 입금액은 3만원이상, 만원단위
                          </span>
                          로 신청가능, 입금전 반드시 계좌문의 하시기바랍니다.
                          <br />
                        </div>
                      </div>
                    </div> */}
                    <div className="con_box10">
                      <table className="write_title_top">
                    
                        <tr>
                          <td className="write_title">금고입금</td>
                          <td className="write_basic">
                            <input
                              className="input1"
                              value={this.state.balance} 
                              onChange={(e: any) => {
                                this.setState({ balance : e.target.value });
                              }}
                            />
                         
                            <a
                              onClick={()=> this.handleChangeToBalance()}
                            >
                                    <span className="btn1_1">입금하기</span>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="write_title">금고출금</td>
                          <td className="write_basic">
                                  <input
                                  className="input1"
                                  value={this.state.point} 
                                  onChange={(e: any) => {
                                    this.setState({ point : e.target.value });
                                  }}
                                />
                                <a
                                   onClick={()=> this.handleChangeToPoint()}
                                  >
                                    <span className="btn1_1">출금하기</span>
                                </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                    {/* <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info3">
                          23:50 ~ 00:30, 휴일 다음 첫 영업일 새벽에는
                          은행점검으로 인해 계좌이체가 지연될 수 있습니다.
                          <br />위 시간 이외에도 몇몇 은행은 추가적 점검시간이
                          따로 있으니 이점 유념하시기 바랍니다.
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="con_box10">
                      <div className="btn_wrap_center">
                        <ul>
                          <li>
                            <a onClick={this.handleDoDeposit}>
                              <span className="btn3_1">입금신청하기</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
