import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SlotService } from "../../service/slot.service";
import { BalanceService } from "../../service/balance.service";
import { UserService } from "../../service/user.service";

import { ConvertDate2, ConverMoeny, ConvertDate , ConvertDateDay} from "../../utility/help";

// import {
//   ToggleMainBoard,
//   ToggleMainRealtime,
// } from "./../../../public/web/js/showid";

// {
//   /* <script type="text/javascript" src="/web/js/showid.js"></script> */
// }

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

interface Props {}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  notices: any;
  renderBalance: string;
}

export class BalanceList extends Component<Props, State> {
  balanceService = new BalanceService();
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      renderBalance: "deposit",
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      notices: [],
    };
  }

  componentDidMount() {
    this.userService.get_user_notices().then((data: any) => {
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    this.balanceService.get_balance_deposit_lock().then((s) => {
      if (s.status === "success") {
        this.setState({ deposits: s.deposits });
      }
    });

    this.balanceService.get_balance_withdraw_lock().then((s) => {
      if (s.status === "success") {
        this.setState({ withdraws: s.withdraws });
      }
    });
  }

  render() {
 
     const RenderWithdraw = () => {
    
       return (
         <div id="mainWithdrawList" style={{ height: '198px'}} className="">
           실시간 출금
         <ul  id="mainWithdrawData" className="realtimeList" style={{position: 'absolute', margin: '0px', padding: '0px', top: '0px'}}>	
           {this.state.withdraws.map((contact: any, i: any) => (
          
             <li style={{margin: '0px', padding: '0px', height: '34px'}} key={`mainDepositList_key_${i}`}>
               <div className="user" style={{fontSize: 16}}>{contact.id}</div>
               <div className="amount">{ConverMoeny(contact.balance)}원</div>
               <div className="date" style={{fontSize: 16}}>{ConvertDateDay(contact.regdate)}</div>
             </li>
           ))}
         </ul>
     </div>
   
       );
     };
     const RenderDeposit = () => {
       //   if (this.state.renderBalance === "deposit") return <></>;
       return (
         <div id="mainDepositList" style={{ height: '240px'}} className="">
           실시간 입금
           <ul  id="mainDepositData" className="realtimeList" style={{position: 'absolute', margin: '0px', padding: '0px', top: '0px'}}>	
             {this.state.deposits.map((contact: any, i: any) => (
            
               <li style={{margin: '0px', padding: '0px', height: '34px'}} key={`DepositList_key_${i}`}>
                 <div className="user" style={{fontSize: 16}}>{contact.id}</div>
                 <div className="amount">{ConverMoeny(contact.balance)}원</div>
                 <div className="date" style={{fontSize: 16}}>{ConvertDateDay(contact.regdate)}</div>
               </li>
             ))}
           </ul>
       </div>
 
       );
     };
    return (
      <div className="visual_wrap">
        <div className="visual">
          <div className="jack_wrap">
            <div className="jack_tit">
              <img src="images/jack_tit.png" style={{visibility : 'hidden'}} />
            </div>
          </div>
          <div className="container demo-2">
            <div id="slider" className="sl-slider-wrapper">
              <div className="main_board" style={{zIndex:1}}>
                  <ul>
                    <li className="board out_now">
                      {RenderDeposit()}
                    </li>
                    <li className="board out_rank">
                      {RenderWithdraw()}
                    </li>
                  </ul>
              </div>
        
              <nav id="nav-dots" className="nav-dots">
                <span className="nav-dot-current"></span>
                <span></span>
                <span></span>
              </nav>
            </div>
          </div>	
        </div>
        </div>			
    );
  }
}



// export class BalanceList extends Component<Props, State> {
//   balanceService = new BalanceService();
//   userService = new UserService();

//   constructor(props: any) {
//     super(props);
//     this.state = {
//       renderBalance: "deposit",
//       mode: Mode.none,
//       slots: [],
//       games: [],
//       withdraws: [],
//       deposits: [],
//       notices: [],
//     };
//   }

//   componentDidMount() {
//     this.userService.get_user_notices().then((data: any) => {
//       if (data.status === "success") {
//         this.setState({ notices: data.notices });
//       }
//     });

//     this.balanceService.get_balance_deposit_lock().then((s) => {
//       if (s.status === "success") {
//         this.setState({ deposits: s.deposits });
//       }
//     });

//     this.balanceService.get_balance_withdraw_lock().then((s) => {
//       if (s.status === "success") {
//         this.setState({ withdraws: s.withdraws });
//       }
//     });
//   }

//   render() {
//     const RenderWithdraw = () => {
      
//       return (
//         <div id="mainWithdrawList" style={{overflow: 'hidden', position: 'relative', height: '198px'}} className="">
//           실시간 출금
//         <ul  id="mainWithdrawData" className="realtimeList" style={{position: 'absolute', margin: '0px', padding: '0px', top: '0px'}}>	
//           {this.state.withdraws.map((contact: any, i: any) => (
            
//             <li style={{margin: '0px', padding: '0px', height: '34px'}} key={`mainDepositList_key_${i}`}>
//               <div className="user" style={{fontSize: 16}}>{contact.id}</div>
//               <div className="amount">{ConverMoeny(contact.balance)}원</div>
//               <div className="date" style={{fontSize: 16}}>{ConvertDateDay(contact.regdate)}</div>
//             </li>
//           ))}
//         </ul>
//     </div>

     
//       );
//     };

//     const RenderDeposit = () => {
//       //   if (this.state.renderBalance === "deposit") return <></>;

//       return (
//         <div id="mainDepositList" style={{overflow: 'hidden', position: 'relative', height: '240px'}} className="">
//           실시간 입금
//           <ul  id="mainDepositData" className="realtimeList" style={{position: 'absolute', margin: '0px', padding: '0px', top: '0px'}}>	
//             {this.state.deposits.map((contact: any, i: any) => (
              
//               <li style={{margin: '0px', padding: '0px', height: '34px'}} key={`DepositList_key_${i}`}>
//                 <div className="user" style={{fontSize: 16}}>{contact.id}</div>
//                 <div className="amount">{ConverMoeny(contact.balance)}원</div>
//                 <div className="date" style={{fontSize: 16}}>{ConvertDateDay(contact.regdate)}</div>
//               </li>
//             ))}
//           </ul>
//       </div>

   
//       );
//     };
//     return (
//       // <div className="media_wrap">
//       //   <div className="media_box">
//       //     <div className="card">
   
//       //     </div>
     
//       //     <div className="card">
//       //       <div className="mainBottomList">
//       //         <ul
//       //           className="messenger_list"
//       //           style={{ margin: "0px", padding: "0px", listStyle: "none" }}
//       //         >
//       //           <li>
//       //             <img src="/web/images/telegram_btn.png" />
//       //           </li>
       
//       //         </ul>
        
//       //           <p
//       //           className="messenger_text"
//       //           style={{
//       //             margin: 0,
//       //             padding: 0,
//       //           }}
//       //         >
//       //           고객센터
//       //         </p>
//       //         <p
//       //           className="messenger_text"
//       //           style={{
//       //             margin: 0,
//       //             padding: 0,
//       //           }}
//       //         >
//       //         </p>
//       //       </div>
//       //     </div>
//       //   </div>
//       // </div>   

//       <div className="visual_wrap">
//         {/* <div className="visual">
//           <div className="jack_wrap">
//             <div className="jack_tit">
//               <img src="images/jack_tit.png"  style={{    visibility: 'hidden'}}/>
//             </div>
//           </div>
//           <div className="container demo-2">
//             <div id="slider" className="sl-slider-wrapper">
//               <div className="main_board" style={{zIndex:1}}>
//                   <ul>
//                     <li className="board out_now">
//                       {RenderDeposit()}
//                     </li>
//                     <li className="board out_rank">
//                       {RenderWithdraw()}
//                     </li>
//                   </ul>
//               </div>
                    
//               <nav id="nav-dots" className="nav-dots">
//                 <span className="nav-dot-current"></span>
//                 <span></span>
//                 <span></span>
//               </nav>
//             </div>
//           </div>	
//         </div> */}
                    
//     </div>			
//     );
//   }
// }
