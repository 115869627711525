import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { confirmAlert } from "react-confirm-alert"; // Import

import { SlotService } from "../../service/slot.service";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

interface Props {}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  
  isOpen : boolean
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      
      isOpen : false,
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    
    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ casionos: s.casiono });
      }
    });
  }

  handleOpenSlot = (code: string, company : string ) => {

    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({isOpen : false})

      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      } else if (data.status === "fix_server") {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoFix,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      } else {
        // this.setState({
        //   popupStatus: PopupStatus.CasinoPermission,
        //   CallPrimary: () => {
        //     this.ClosePop();
        //   },
        // });
      }
    });
  };
  
  handleGame = (name: string) => {
    this.slotService.get_slot_by_company(name).then((s: any) => {
      if (s.status === "success") {
        this.setState({ games: s.games, mode: Mode.game });
      }
    });
  };

  RenderSlot = (info: any) => {

    return (
      <li key={`key${info.nameEn}kkk`}
        className={info.nameEn}
        onClick={() => {
          if (info.used === "y") {
            this.setState({ mode: Mode.none });
            this.handleGame(info.code);
          } else {
            confirmAlert({
              title: "점검중",
              message: "현재 해당게임은 점검중입니다 .",
              buttons: [
                {
                  label: "확인",
                  onClick: () => {},
                },
              ],
            });
          }
        }}
      >
        <div style={{backgroundImage: `url(${info.mobileImg})`}}>
          {/* <span className="studio">{info.nameKo}</span> */}
          {/* <span className="ico">
            <img src={`/web/images/icon-${info.nameEn}.png`} />
          </span> */}
          <span className="text">{info.nameKo}</span>
          <span className="play">플레이하기</span>
        </div>
      </li>
    );
  };

  
  RenderCasino = (info: any) => {

    return (
      <li key={`key${info.nameEn}kkk`}
        className={info.nameEn}
        onClick={() => {
          if (info.used === "y") {
            this.handleOpenSlot(info.code, info.gameCompany)
          } else {
            confirmAlert({
              title: "점검중",
              message: "현재 해당게임은 점검중입니다 .",
              buttons: [
                {
                  label: "확인",
                  onClick: () => {},
                },
              ],
            });
          }
        }}
        style={{ width: '250px', height: '160px'}}
      >
        <div style={{backgroundImage: `url(${info.mobileImg})`, width: '100%'}} >
     
          <span className="text">{info.nameKo}</span>
          <span className="play">플레이하기</span>
        </div>
      </li>
    );
  };

  RenderGame = (info: any) => {
    return (
      <li
        className={info.nameEn}
      

        style={{
          top: "65%",
          height:"170px",
          width:"130px",
          padding : '10px'
        }}
      >
        <div >
          <span
            className="studio"
            style={{
              position: "absolute",
              fontSize: 14,
               color:"#fff",
               top: "90%",
              left: "40%",
              width:"130px"

              // transform: `translateX(-40%) translateY(50%)`,
            }}
          >
            {info.nameKo}
          </span>
          <img
            onClick={() => {
              this.handleOpenSlot(info.code, info.gameCompany)
            }}
            src={`${info.imgUrl}`}
            style={{
              maxWidth: "130px",
              maxHeight: "130px",
            }}
          />
          {/* <span className="text">{info.nameKo}</span>
          <span className="play">{info.nameKo}</span> */}
        </div>
      </li>
    );
  };

  render() {
    if (this.state.mode === Mode.none) {
      return (
        <div className="game_wrap">
          <div className="game_box">
            <div
              style={{
                textAlign: "center",
                zoom: 10,
              }}
            >
              <CircularProgress />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.mode === Mode.game) {
      return (
        <div className="game_wrap">
          <div className="game_box">
            <ul className="slots">
              {this.state.games.map((row: any) => this.RenderGame(row))}
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div className="game_wrap">
        <div className="game_box"  style={{textAlign : 'center'}}>
          <div style={{ padding: '40px'}}>
            <img src="/web/images/slot.png"  />
          </div>

          <ul className="slots"  style={{left : '0px'}}>
            {this.state.slots.map((row: any) => this.RenderSlot(row))}
          </ul>

          {
            process.env.REACT_APP_API_CASINO === 'Y' && (
                <>
                  <div style={{ padding: '20px'}}>
                    <img src="/web/images/livecasino.png"  />
                  </div>

                  <ul className="slots" style={{left : '0px'}}>
                    {this.state.casionos.map((row: any) => this.RenderCasino(row))}
                  </ul>
              </>
            )
          }
        </div>
      </div>
    );
  }
}
